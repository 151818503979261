/* eslint-disable no-restricted-imports */
import 'designsystem/src/generated/pillar/professionals/tokens.css';
import 'designsystem/src/generated/colorScheme/neutral/tokens.css';
import 'designsystem/src/generated/colorScheme/collection/tokens.css';
import 'designsystem/src/generated/colorScheme/festival/tokens.css';
import 'designsystem/src/generated/colorScheme/professionals/tokens.css';
import 'designsystem/src/generated/colorScheme/institute/tokens.css';
import fallback from 'designsystem/src/assets/images/placeholder.svg';
/* eslint-enable no-restricted-imports */
import { GlobalStyle, professionalsTheme, ThemeProvider, useFontLoaded } from 'designsystem';
import { AppProps } from 'next/app';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { NextAdapter } from 'next-query-params';
import { QueryParamProvider } from 'use-query-params';
import { useGlobalsQuery } from '../gql/cms';
import siteHandleByLocale from '../utils/siteHandleByLocale';
import { SessionProvider, signOut, useSession } from 'next-auth/react';
import { DefaultSeo } from 'next-seo';
import ProfessionalsNavigation from '../components/ProfessionalsNavigation';
import { ATProviders, GlobalModals, IdfaIntlProvider, PillarFooter, Trengo, useLastPageCookie } from 'shared';
import NextProgress from 'next-progress';
import { useTheme } from '@emotion/react';
import Script from 'next/script';
import Head from 'next/head';

const App: FC<AppProps> = props => {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false,
                        retry: 2,
                        refetchOnMount: false,
                        staleTime: 1000 * 10,
                    },
                },
            })
    );
    const { pageProps } = props;
    const { locale, defaultLocale } = useRouter();
    useLastPageCookie();

    return (
        <QueryParamProvider adapter={NextAdapter}>
            <Head>
                <link rel="icon" href="/favicon.ico" sizes="any" />
            </Head>
            {process.env.NODE_ENV !== 'development' && (
                <>
                    <Script id="google-tag-manager" strategy="afterInteractive">
                        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}');
      `}
                    </Script>
                    <noscript>
                        <iframe
                            title="gtm-noscript"
                            src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}}`}
                            height="0"
                            width="0"
                            style={{ display: 'none', visibility: 'hidden' }}
                        />
                    </noscript>
                </>
            )}
            <DefaultSeo
                openGraph={{
                    type: 'website',
                    locale,
                    url: 'https://www.idfa.nl/',
                    siteName: 'IDFA',
                    images: [
                        {
                            url: fallback,
                        },
                    ],
                }}
                additionalMetaTags={[
                    {
                        name: 'author',
                        content: 'Oberon Amsterdam, www.oberon.nl',
                    },
                ]}
                titleTemplate="IDFA Professionals | %s"
                description="IDFA Professionals connects filmmakers and professionals from the international industry to create and share the creative documentaries of tomorrow."
                twitter={{
                    handle: '@idfa',
                    site: '@idfa',
                    cardType: 'summary_large_image',
                }}
            />
            <SessionProvider session={pageProps.session}>
                <ThemeProvider theme={professionalsTheme}>
                    <IdfaIntlProvider
                        locale={locale as string}
                        defaultLocale={defaultLocale}
                        messages={pageProps.intlMessages}
                    >
                        <QueryClientProvider client={queryClient}>
                            <Hydrate state={pageProps.dehydratedState}>
                                <ATProviders>
                                    <GlobalStyle />
                                    <Main {...props} />
                                    <ReactQueryDevtools initialIsOpen={false} />
                                </ATProviders>
                            </Hydrate>
                        </QueryClientProvider>
                    </IdfaIntlProvider>
                </ThemeProvider>
            </SessionProvider>
        </QueryParamProvider>
    );
};

const Main: FC<AppProps> = props => {
    const { pageProps, Component, router } = props;
    const site = siteHandleByLocale[router.locale];
    const { isFallback } = useRouter();
    const { data, isLoading } = useGlobalsQuery(
        {
            site,
        },
        { enabled: !isFallback }
    );
    const graphikLoaded = useFontLoaded('Graphik');
    const session = useSession();
    useEffect(() => {
        if (session.data?.error === 'RefreshAccessTokenError') {
            signOut({ redirect: false });
        }
    }, [session.data?.error]);

    const { tokens } = useTheme();

    return (
        <main
            id="root"
            style={{ minWidth: 'fit-content' }}
            data-font-loaded={graphikLoaded !== null ? graphikLoaded : undefined}
        >
            <NextProgress delay={300} options={{ showSpinner: false }} color={tokens.SyntaxPrimaryColorDarker} />
            <ProfessionalsNavigation isLoading={isLoading} globals={data} />
            <Component {...pageProps} />
            <Trengo />
            <PillarFooter
                isLoading={isLoading}
                globals={data}
                pillarTitle={<FormattedMessage defaultMessage="IDFA Professionals" />}
            />
            <GlobalModals />
        </main>
    );
};

export default App;
