/* eslint-disable */
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useCraft } from 'shared';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: any;
    Number: any;
    QueryArgument: any;
};

export type AssetCriteriaInput = {
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the assets’ files’ last-modified dates. */
    dateModified?: InputMaybe<Scalars['String']>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the assets’ filenames. */
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the folders the assets belong to, per the folders’ IDs. */
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on whether the assets have alternative text. */
    hasAlt?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the assets’ image heights. */
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Broadens the query results to include assets from any of the subfolders of the folder specified by `folderId`. */
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the assets’ file kinds. */
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the assets’ file sizes (in bytes). */
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the user the assets were uploaded by, per the user’s ID. */
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the volumes the assets belong to, per the volumes’ handles. */
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the volumes the assets belong to, per the volumes’ IDs. */
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the assets’ image widths. */
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** A list of transform handles to preload. */
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CategoryCriteriaInput = {
    /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `ancestorOf`. */
    ancestorDist?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that are ancestors of another element in its structure, provided by its ID. */
    ancestorOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `descendantOf`. */
    descendantDist?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that are descendants of another element in its structure provided by its ID. */
    descendantOf?: InputMaybe<Scalars['Int']>;
    /** Whether to only return categories that the user has permission to edit. */
    editable?: InputMaybe<Scalars['Boolean']>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the category groups the categories belong to per the group’s handles. */
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the category groups the categories belong to, per the groups’ IDs. */
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on whether the elements have any descendants in their structure. */
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on whether the elements are “leaves” in their structure (element with no descendants). */
    leaves?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ level within the structure. */
    level?: InputMaybe<Scalars['Int']>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only the entry that comes immediately after another element in its structure, provided by its ID. */
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Narrows the query results to only entries that are positioned after another element in its structure, provided by its ID. */
    positionedAfter?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only entries that are positioned before another element in its structure, provided by its ID. */
    positionedBefore?: InputMaybe<Scalars['Int']>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only the entry that comes immediately before another element in its structure, provided by its ID. */
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which structure data should be joined into the query. */
    structureId?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Explicitly determines whether the query should join in the structure data. */
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type EntryCriteriaInput = {
    /** Narrows the query results to only entries that were posted on or after a certain date. */
    after?: InputMaybe<Scalars['String']>;
    /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `ancestorOf`. */
    ancestorDist?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that are ancestors of another element in its structure, provided by its ID. */
    ancestorOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on the user group the entries’ authors belong to. */
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the user group the entries’ authors belong to, per the groups’ IDs. */
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the entries’ authors. */
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    bannerImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only entries that were posted before a certain date. */
    before?: InputMaybe<Scalars['String']>;
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `descendantOf`. */
    descendantDist?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that are descendants of another element in its structure provided by its ID. */
    descendantOf?: InputMaybe<Scalars['Int']>;
    /** Whether to only return entries that the user has permission to edit. */
    editable?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the entries’ expiry dates. */
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    featuredFilms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    formConfirmationHeading?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    formConfirmationMessage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    formType?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on whether the elements have any descendants in their structure. */
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    headerImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeAlert?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHeroCards?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHeroStories?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    introText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on whether the elements are “leaves” in their structure (element with no descendants). */
    leaves?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ level within the structure. */
    level?: InputMaybe<Scalars['Int']>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    linkObject?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    linkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    navCtaText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    navIsFeatured?: InputMaybe<Scalars['Boolean']>;
    navThumbnail?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only the entry that comes immediately after another element in its structure, provided by its ID. */
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    partnerListingsText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only entries that are positioned after another element in its structure, provided by its ID. */
    positionedAfter?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only entries that are positioned before another element in its structure, provided by its ID. */
    positionedBefore?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on the entries’ post dates. */
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only the entry that comes immediately before another element in its structure, provided by its ID. */
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Narrows the query results based on the section handles the entries belong to. */
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the sections the entries belong to, per the sections’ IDs. */
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sponsorBannerSet?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    sponsorBanners?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    storyCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Determines which structure data should be joined into the query. */
    structureId?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the entries’ entry type handles. */
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the entries’ entry types, per the types’ IDs. */
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Explicitly determines whether the query should join in the structure data. */
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type TagCriteriaInput = {
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the tag groups the tags belong to per the group’s handles. */
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the tag groups the tags belong to, per the groups’ IDs. */
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UserCriteriaInput = {
    /** Narrows the query results based on whether the users have uploaded any assets. */
    assetUploaders?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on whether the users are listed as the author of any entries. */
    authors?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the users’ email addresses. */
    email?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the users’ first names. */
    firstName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the users’ full names. */
    fullName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the user group the users belong to. */
    group?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the user group the users belong to, per the groups’ IDs. */
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only users that have (or don’t have) a user photo. */
    hasPhoto?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the users’ last names. */
    lastName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the users’ usernames. */
    username?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentBlocksQueryVariables = Exact<{
    slug: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type ContentBlocksQuery = {
    __typename?: 'Query';
    entry?:
        | { __typename: 'festivalContentPages_festivalCollectionPage_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_guestbookPage_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_informationPage_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_navigationPage_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_newsOverview_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_pressAndIndustryPage_Entry'; id?: string | null }
        | { __typename: 'festivalContentPages_programPage_Entry'; id?: string | null }
        | { __typename: 'festivalHomepage_festivalHomepage_Entry'; id?: string | null }
        | { __typename: 'festivalNewsArticles_news_Entry'; id?: string | null }
        | { __typename: 'festivalPathways_pathway_Entry'; id?: string | null }
        | { __typename: 'footer_footerLink_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_archiveSearchPage_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_cinemaProgramPage_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_docschoolCollectionPage_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_formPage_Entry'; id?: string | null }
        | {
              __typename: 'instituteContentPages_informationPage_Entry';
              id?: string | null;
              contentBlocks: Array<
                  | {
                        __typename: 'contentBlocks_accordion_BlockType';
                        id?: string | null;
                        accordionItems: Array<{
                            __typename?: 'accordionItems_BlockType';
                            id?: string | null;
                            heading?: string | null;
                            body?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_archiveFilm_BlockType';
                        id?: string | null;
                        filmId?: Array<string> | null;
                    }
                  | {
                        __typename: 'contentBlocks_button_BlockType';
                        id?: string | null;
                        label?: string | null;
                        linkObject?: {
                            __typename?: 'linkField_Link';
                            url?: string | null;
                            target?: string | null;
                            element?:
                                | { __typename?: 'Address'; uri?: string | null }
                                | { __typename?: 'Element'; uri?: string | null }
                                | { __typename?: 'User'; uri?: string | null }
                                | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                                | { __typename?: 'blocks_BlockType'; uri?: string | null }
                                | { __typename?: 'carousel_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                                | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                                | { __typename?: 'destination_BlockType'; uri?: string | null }
                                | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                                | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                                | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                                | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                                | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                                | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                                | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                                | { __typename?: 'links_BlockType'; uri?: string | null }
                                | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                                | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                                | { __typename?: 'newsCategories_Category'; uri?: string | null }
                                | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                                | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_informationPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                                | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                                | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                                | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                                | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                                | { __typename?: 'slides_BlockType'; uri?: string | null }
                                | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                                | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                                | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                                | { __typename?: 'storyCategories_Category'; uri?: string | null }
                                | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                                | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                                | { __typename?: 'uploads_Asset'; uri?: string | null }
                                | null;
                        } | null;
                    }
                  | {
                        __typename: 'contentBlocks_carousel_BlockType';
                        id?: string | null;
                        slides: Array<{
                            __typename?: 'slides_BlockType';
                            id?: string | null;
                            caption?: string | null;
                            image: Array<{
                                __typename?: 'uploads_Asset';
                                id?: string | null;
                                alt?: string | null;
                                url?: string | null;
                                title?: string | null;
                                width?: number | null;
                                height?: number | null;
                                blurHash?: string | null;
                                webp_100w?: string | null;
                                webp_400w?: string | null;
                                webp_860w?: string | null;
                                webp_1280w?: string | null;
                                webp_1920w?: string | null;
                                webp_2560w?: string | null;
                                jpeg_100w?: string | null;
                                jpeg_400w?: string | null;
                                jpeg_860w?: string | null;
                                jpeg_1280w?: string | null;
                                jpeg_1920w?: string | null;
                                jpeg_2560w?: string | null;
                            } | null>;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_cinemaItem_BlockType';
                        id?: string | null;
                        itemId?: Array<string> | null;
                    }
                  | {
                        __typename: 'contentBlocks_dataTable_BlockType';
                        id?: string | null;
                        table?: {
                            __typename?: 'table_TableMakerField';
                            rows?: Array<Array<string | null> | null> | null;
                            columns?: Array<{
                                __typename?: 'table_TableMakerField_column';
                                width?: string | null;
                                heading?: string | null;
                                align?: string | null;
                            } | null> | null;
                        } | null;
                    }
                  | {
                        __typename: 'contentBlocks_docSchoolFilmTile_BlockType';
                        id?: string | null;
                        filmId?: Array<string> | null;
                    }
                  | { __typename: 'contentBlocks_festivalItem_BlockType'; itemId?: Array<string> | null }
                  | { __typename: 'contentBlocks_googleMapEmbed_BlockType'; embedUrl?: string | null }
                  | { __typename: 'contentBlocks_heading_BlockType'; id?: string | null; text?: string | null }
                  | {
                        __typename: 'contentBlocks_image_BlockType';
                        id?: string | null;
                        caption?: string | null;
                        width?: boolean | null;
                        image: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_images_BlockType';
                        id?: string | null;
                        caption?: string | null;
                        images: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_navigation_BlockType';
                        id?: string | null;
                        blocks: Array<{
                            __typename?: 'blocks_BlockType';
                            id?: string | null;
                            color?: string | null;
                            label?: string | null;
                            thumbnail: Array<{
                                __typename?: 'uploads_Asset';
                                id?: string | null;
                                alt?: string | null;
                                url?: string | null;
                                title?: string | null;
                                width?: number | null;
                                height?: number | null;
                                blurHash?: string | null;
                                webp_100w?: string | null;
                                webp_400w?: string | null;
                                webp_860w?: string | null;
                                webp_1280w?: string | null;
                                webp_1920w?: string | null;
                                webp_2560w?: string | null;
                                jpeg_100w?: string | null;
                                jpeg_400w?: string | null;
                                jpeg_860w?: string | null;
                                jpeg_1280w?: string | null;
                                jpeg_1920w?: string | null;
                                jpeg_2560w?: string | null;
                            } | null>;
                            linkObject?: {
                                __typename?: 'linkField_Link';
                                url?: string | null;
                                element?:
                                    | { __typename?: 'Address'; uri?: string | null }
                                    | { __typename?: 'Element'; uri?: string | null }
                                    | { __typename?: 'User'; uri?: string | null }
                                    | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'blocks_BlockType'; uri?: string | null }
                                    | { __typename?: 'carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                                    | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                                    | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_destinationList_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                                    | { __typename?: 'links_BlockType'; uri?: string | null }
                                    | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                                    | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'newsCategories_Category'; uri?: string | null }
                                    | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                                    | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_navigationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                                    | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                                    | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                                    | { __typename?: 'slides_BlockType'; uri?: string | null }
                                    | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                                    | { __typename?: 'storyCategories_Category'; uri?: string | null }
                                    | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                                    | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                                    | { __typename?: 'uploads_Asset'; uri?: string | null }
                                    | null;
                            } | null;
                        } | null>;
                    }
                  | { __typename: 'contentBlocks_paragraph_BlockType'; id?: string | null; text?: string | null }
                  | { __typename: 'contentBlocks_professionalsFilm_BlockType'; filmId?: Array<string> | null }
                  | {
                        __typename: 'contentBlocks_quote_BlockType';
                        id?: string | null;
                        quote?: string | null;
                        source?: string | null;
                    }
                  | {
                        __typename: 'contentBlocks_ranking_BlockType';
                        id?: string | null;
                        rankingItems: Array<{
                            __typename?: 'rankingItems_BlockType';
                            id?: string | null;
                            label?: string | null;
                            linkObject?: {
                                __typename?: 'linkField_Link';
                                text?: string | null;
                                url?: string | null;
                                ariaLabel?: string | null;
                                target?: string | null;
                                element?:
                                    | { __typename?: 'Address'; uri?: string | null }
                                    | { __typename?: 'Element'; uri?: string | null }
                                    | { __typename?: 'User'; uri?: string | null }
                                    | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'blocks_BlockType'; uri?: string | null }
                                    | { __typename?: 'carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                                    | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                                    | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_destinationList_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                                    | { __typename?: 'links_BlockType'; uri?: string | null }
                                    | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                                    | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'newsCategories_Category'; uri?: string | null }
                                    | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                                    | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_navigationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                                    | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                                    | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                                    | { __typename?: 'slides_BlockType'; uri?: string | null }
                                    | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                                    | { __typename?: 'storyCategories_Category'; uri?: string | null }
                                    | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                                    | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                                    | { __typename?: 'uploads_Asset'; uri?: string | null }
                                    | null;
                            } | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_sponsorBlock_BlockType';
                        id?: string | null;
                        sponsors: Array<{
                            __typename?: 'sponsor_BlockType';
                            id?: string | null;
                            title?: string | null;
                            linkTo?: string | null;
                            logo: Array<{
                                __typename?: 'uploads_Asset';
                                id?: string | null;
                                alt?: string | null;
                                url?: string | null;
                                title?: string | null;
                                width?: number | null;
                                height?: number | null;
                                blurHash?: string | null;
                                webp_100w?: string | null;
                                webp_400w?: string | null;
                                webp_860w?: string | null;
                                webp_1280w?: string | null;
                                webp_1920w?: string | null;
                                webp_2560w?: string | null;
                                jpeg_100w?: string | null;
                                jpeg_400w?: string | null;
                                jpeg_860w?: string | null;
                                jpeg_1280w?: string | null;
                                jpeg_1920w?: string | null;
                                jpeg_2560w?: string | null;
                            } | null>;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_styledTable_BlockType';
                        id?: string | null;
                        styledTableRows: Array<{
                            __typename?: 'styledTableRows_BlockType';
                            id?: string | null;
                            column1?: string | null;
                            column2?: string | null;
                            column3?: string | null;
                        } | null>;
                        styledTableHeadings: Array<{
                            __typename?: 'styledTableHeadings_BlockType';
                            id?: string | null;
                            heading1?: string | null;
                            heading2?: string | null;
                            heading3?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_ticketBlock_BlockType';
                        id?: string | null;
                        showOrTicketId?: string | null;
                        heading?: string | null;
                        location?: string | null;
                        startDate?: any | null;
                        endDate?: any | null;
                        ticketType?: string | null;
                    }
                  | {
                        __typename: 'contentBlocks_videoPlayer_BlockType';
                        id?: string | null;
                        videoUrl?: string | null;
                        caption?: string | null;
                    }
                  | null
              >;
          }
        | { __typename: 'instituteContentPages_navigationPage_Entry'; id?: string | null }
        | { __typename: 'instituteContentPages_newsOverview_Entry'; id?: string | null }
        | { __typename: 'instituteHomepage_instituteHomepage_Entry'; id?: string | null }
        | { __typename: 'instituteNewsArticles_news_Entry'; id?: string | null }
        | { __typename: 'instituteStrands_strand_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_berthaFundCollectionPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_doclabCollectionPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_forumCollectionPage_Entry'; id?: string | null }
        | {
              __typename: 'professionalsContentPages_informationPage_Entry';
              id?: string | null;
              contentBlocks: Array<
                  | {
                        __typename: 'contentBlocks_accordion_BlockType';
                        id?: string | null;
                        accordionItems: Array<{
                            __typename?: 'accordionItems_BlockType';
                            id?: string | null;
                            heading?: string | null;
                            body?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_archiveFilm_BlockType';
                        id?: string | null;
                        filmId?: Array<string> | null;
                    }
                  | {
                        __typename: 'contentBlocks_button_BlockType';
                        id?: string | null;
                        label?: string | null;
                        linkObject?: {
                            __typename?: 'linkField_Link';
                            url?: string | null;
                            target?: string | null;
                            element?:
                                | { __typename?: 'Address'; uri?: string | null }
                                | { __typename?: 'Element'; uri?: string | null }
                                | { __typename?: 'User'; uri?: string | null }
                                | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                                | { __typename?: 'blocks_BlockType'; uri?: string | null }
                                | { __typename?: 'carousel_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                                | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                                | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                                | { __typename?: 'destination_BlockType'; uri?: string | null }
                                | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                                | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                                | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                                | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                                | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                                | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                                | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                                | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                                | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                                | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                                | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                                | { __typename?: 'links_BlockType'; uri?: string | null }
                                | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                                | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                                | { __typename?: 'newsCategories_Category'; uri?: string | null }
                                | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                                | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                                | {
                                      __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_informationPage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
                                | {
                                      __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                      uri?: string | null;
                                  }
                                | {
                                      __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                      uri?: string | null;
                                  }
                                | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                                | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                                | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                                | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                                | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                                | { __typename?: 'slides_BlockType'; uri?: string | null }
                                | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                                | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                                | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                                | { __typename?: 'storyCategories_Category'; uri?: string | null }
                                | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                                | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                                | { __typename?: 'uploads_Asset'; uri?: string | null }
                                | null;
                        } | null;
                    }
                  | {
                        __typename: 'contentBlocks_carousel_BlockType';
                        id?: string | null;
                        slides: Array<{
                            __typename?: 'slides_BlockType';
                            id?: string | null;
                            caption?: string | null;
                            image: Array<{
                                __typename?: 'uploads_Asset';
                                id?: string | null;
                                alt?: string | null;
                                url?: string | null;
                                title?: string | null;
                                width?: number | null;
                                height?: number | null;
                                blurHash?: string | null;
                                webp_100w?: string | null;
                                webp_400w?: string | null;
                                webp_860w?: string | null;
                                webp_1280w?: string | null;
                                webp_1920w?: string | null;
                                webp_2560w?: string | null;
                                jpeg_100w?: string | null;
                                jpeg_400w?: string | null;
                                jpeg_860w?: string | null;
                                jpeg_1280w?: string | null;
                                jpeg_1920w?: string | null;
                                jpeg_2560w?: string | null;
                            } | null>;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_cinemaItem_BlockType';
                        id?: string | null;
                        itemId?: Array<string> | null;
                    }
                  | {
                        __typename: 'contentBlocks_dataTable_BlockType';
                        id?: string | null;
                        table?: {
                            __typename?: 'table_TableMakerField';
                            rows?: Array<Array<string | null> | null> | null;
                            columns?: Array<{
                                __typename?: 'table_TableMakerField_column';
                                width?: string | null;
                                heading?: string | null;
                                align?: string | null;
                            } | null> | null;
                        } | null;
                    }
                  | {
                        __typename: 'contentBlocks_docSchoolFilmTile_BlockType';
                        id?: string | null;
                        filmId?: Array<string> | null;
                    }
                  | { __typename: 'contentBlocks_festivalItem_BlockType'; itemId?: Array<string> | null }
                  | { __typename: 'contentBlocks_googleMapEmbed_BlockType'; embedUrl?: string | null }
                  | { __typename: 'contentBlocks_heading_BlockType'; id?: string | null; text?: string | null }
                  | {
                        __typename: 'contentBlocks_image_BlockType';
                        id?: string | null;
                        caption?: string | null;
                        width?: boolean | null;
                        image: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_images_BlockType';
                        id?: string | null;
                        caption?: string | null;
                        images: Array<{
                            __typename?: 'uploads_Asset';
                            id?: string | null;
                            alt?: string | null;
                            url?: string | null;
                            title?: string | null;
                            width?: number | null;
                            height?: number | null;
                            blurHash?: string | null;
                            webp_100w?: string | null;
                            webp_400w?: string | null;
                            webp_860w?: string | null;
                            webp_1280w?: string | null;
                            webp_1920w?: string | null;
                            webp_2560w?: string | null;
                            jpeg_100w?: string | null;
                            jpeg_400w?: string | null;
                            jpeg_860w?: string | null;
                            jpeg_1280w?: string | null;
                            jpeg_1920w?: string | null;
                            jpeg_2560w?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_navigation_BlockType';
                        id?: string | null;
                        blocks: Array<{
                            __typename?: 'blocks_BlockType';
                            id?: string | null;
                            color?: string | null;
                            label?: string | null;
                            thumbnail: Array<{
                                __typename?: 'uploads_Asset';
                                id?: string | null;
                                alt?: string | null;
                                url?: string | null;
                                title?: string | null;
                                width?: number | null;
                                height?: number | null;
                                blurHash?: string | null;
                                webp_100w?: string | null;
                                webp_400w?: string | null;
                                webp_860w?: string | null;
                                webp_1280w?: string | null;
                                webp_1920w?: string | null;
                                webp_2560w?: string | null;
                                jpeg_100w?: string | null;
                                jpeg_400w?: string | null;
                                jpeg_860w?: string | null;
                                jpeg_1280w?: string | null;
                                jpeg_1920w?: string | null;
                                jpeg_2560w?: string | null;
                            } | null>;
                            linkObject?: {
                                __typename?: 'linkField_Link';
                                url?: string | null;
                                element?:
                                    | { __typename?: 'Address'; uri?: string | null }
                                    | { __typename?: 'Element'; uri?: string | null }
                                    | { __typename?: 'User'; uri?: string | null }
                                    | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'blocks_BlockType'; uri?: string | null }
                                    | { __typename?: 'carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                                    | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                                    | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_destinationList_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                                    | { __typename?: 'links_BlockType'; uri?: string | null }
                                    | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                                    | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'newsCategories_Category'; uri?: string | null }
                                    | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                                    | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_navigationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                                    | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                                    | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                                    | { __typename?: 'slides_BlockType'; uri?: string | null }
                                    | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                                    | { __typename?: 'storyCategories_Category'; uri?: string | null }
                                    | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                                    | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                                    | { __typename?: 'uploads_Asset'; uri?: string | null }
                                    | null;
                            } | null;
                        } | null>;
                    }
                  | { __typename: 'contentBlocks_paragraph_BlockType'; id?: string | null; text?: string | null }
                  | { __typename: 'contentBlocks_professionalsFilm_BlockType'; filmId?: Array<string> | null }
                  | {
                        __typename: 'contentBlocks_quote_BlockType';
                        id?: string | null;
                        quote?: string | null;
                        source?: string | null;
                    }
                  | {
                        __typename: 'contentBlocks_ranking_BlockType';
                        id?: string | null;
                        rankingItems: Array<{
                            __typename?: 'rankingItems_BlockType';
                            id?: string | null;
                            label?: string | null;
                            linkObject?: {
                                __typename?: 'linkField_Link';
                                text?: string | null;
                                url?: string | null;
                                ariaLabel?: string | null;
                                target?: string | null;
                                element?:
                                    | { __typename?: 'Address'; uri?: string | null }
                                    | { __typename?: 'Element'; uri?: string | null }
                                    | { __typename?: 'User'; uri?: string | null }
                                    | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'blocks_BlockType'; uri?: string | null }
                                    | { __typename?: 'carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                                    | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                                    | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_festivalCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'festivalContentPages_pressAndIndustryPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                                    | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                                    | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_destinationList_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                                    | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_archiveSearchPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_cinemaProgramPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'instituteContentPages_docschoolCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                                    | {
                                          __typename?: 'instituteContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                                    | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                                    | { __typename?: 'links_BlockType'; uri?: string | null }
                                    | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                                    | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                                    | { __typename?: 'newsCategories_Category'; uri?: string | null }
                                    | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                                    | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                                    | {
                                          __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_doclabCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_forumCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_informationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_navigationPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsContentPages_storiesOverview_Entry';
                                          uri?: string | null;
                                      }
                                    | {
                                          __typename?: 'professionalsHomepage_professionalsHomepage_Entry';
                                          uri?: string | null;
                                      }
                                    | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                                    | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                                    | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                                    | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                                    | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                                    | { __typename?: 'slides_BlockType'; uri?: string | null }
                                    | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                                    | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                                    | { __typename?: 'storyCategories_Category'; uri?: string | null }
                                    | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                                    | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                                    | { __typename?: 'uploads_Asset'; uri?: string | null }
                                    | null;
                            } | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_sponsorBlock_BlockType';
                        id?: string | null;
                        sponsors: Array<{
                            __typename?: 'sponsor_BlockType';
                            id?: string | null;
                            title?: string | null;
                            linkTo?: string | null;
                            logo: Array<{
                                __typename?: 'uploads_Asset';
                                id?: string | null;
                                alt?: string | null;
                                url?: string | null;
                                title?: string | null;
                                width?: number | null;
                                height?: number | null;
                                blurHash?: string | null;
                                webp_100w?: string | null;
                                webp_400w?: string | null;
                                webp_860w?: string | null;
                                webp_1280w?: string | null;
                                webp_1920w?: string | null;
                                webp_2560w?: string | null;
                                jpeg_100w?: string | null;
                                jpeg_400w?: string | null;
                                jpeg_860w?: string | null;
                                jpeg_1280w?: string | null;
                                jpeg_1920w?: string | null;
                                jpeg_2560w?: string | null;
                            } | null>;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_styledTable_BlockType';
                        id?: string | null;
                        styledTableRows: Array<{
                            __typename?: 'styledTableRows_BlockType';
                            id?: string | null;
                            column1?: string | null;
                            column2?: string | null;
                            column3?: string | null;
                        } | null>;
                        styledTableHeadings: Array<{
                            __typename?: 'styledTableHeadings_BlockType';
                            id?: string | null;
                            heading1?: string | null;
                            heading2?: string | null;
                            heading3?: string | null;
                        } | null>;
                    }
                  | {
                        __typename: 'contentBlocks_ticketBlock_BlockType';
                        id?: string | null;
                        showOrTicketId?: string | null;
                        heading?: string | null;
                        location?: string | null;
                        startDate?: any | null;
                        endDate?: any | null;
                        ticketType?: string | null;
                    }
                  | {
                        __typename: 'contentBlocks_videoPlayer_BlockType';
                        id?: string | null;
                        videoUrl?: string | null;
                        caption?: string | null;
                    }
                  | null
              >;
          }
        | { __typename: 'professionalsContentPages_navigationPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; id?: string | null }
        | { __typename: 'professionalsContentPages_storiesOverview_Entry'; id?: string | null }
        | { __typename: 'professionalsHomepage_professionalsHomepage_Entry'; id?: string | null }
        | { __typename: 'professionalsStories_story_Entry'; id?: string | null }
        | { __typename: 'sponsorBannerSets_bannerSet_Entry'; id?: string | null }
        | { __typename: 'sponsorBanners_banner_Entry'; id?: string | null }
        | null;
};

type ContentBlocks_ContentBlocks_Accordion_BlockType_Fragment = {
    __typename: 'contentBlocks_accordion_BlockType';
    id?: string | null;
    accordionItems: Array<{
        __typename?: 'accordionItems_BlockType';
        id?: string | null;
        heading?: string | null;
        body?: string | null;
    } | null>;
};

type ContentBlocks_ContentBlocks_ArchiveFilm_BlockType_Fragment = {
    __typename: 'contentBlocks_archiveFilm_BlockType';
    id?: string | null;
    filmId?: Array<string> | null;
};

type ContentBlocks_ContentBlocks_Button_BlockType_Fragment = {
    __typename: 'contentBlocks_button_BlockType';
    id?: string | null;
    label?: string | null;
    linkObject?: {
        __typename?: 'linkField_Link';
        url?: string | null;
        target?: string | null;
        element?:
            | { __typename?: 'Address'; uri?: string | null }
            | { __typename?: 'Element'; uri?: string | null }
            | { __typename?: 'User'; uri?: string | null }
            | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
            | { __typename?: 'blocks_BlockType'; uri?: string | null }
            | { __typename?: 'carousel_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
            | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
            | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
            | { __typename?: 'destination_BlockType'; uri?: string | null }
            | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
            | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
            | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
            | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
            | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
            | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
            | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
            | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
            | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
            | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
            | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
            | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
            | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
            | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
            | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
            | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
            | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
            | { __typename?: 'links_BlockType'; uri?: string | null }
            | { __typename?: 'listGroup_BlockType'; uri?: string | null }
            | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
            | { __typename?: 'newsCategories_Category'; uri?: string | null }
            | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
            | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
            | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
            | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
            | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
            | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
            | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
            | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
            | { __typename?: 'slides_BlockType'; uri?: string | null }
            | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
            | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
            | { __typename?: 'sponsor_BlockType'; uri?: string | null }
            | { __typename?: 'storyCategories_Category'; uri?: string | null }
            | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
            | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
            | { __typename?: 'uploads_Asset'; uri?: string | null }
            | null;
    } | null;
};

type ContentBlocks_ContentBlocks_Carousel_BlockType_Fragment = {
    __typename: 'contentBlocks_carousel_BlockType';
    id?: string | null;
    slides: Array<{
        __typename?: 'slides_BlockType';
        id?: string | null;
        caption?: string | null;
        image: Array<{
            __typename?: 'uploads_Asset';
            id?: string | null;
            alt?: string | null;
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            blurHash?: string | null;
            webp_100w?: string | null;
            webp_400w?: string | null;
            webp_860w?: string | null;
            webp_1280w?: string | null;
            webp_1920w?: string | null;
            webp_2560w?: string | null;
            jpeg_100w?: string | null;
            jpeg_400w?: string | null;
            jpeg_860w?: string | null;
            jpeg_1280w?: string | null;
            jpeg_1920w?: string | null;
            jpeg_2560w?: string | null;
        } | null>;
    } | null>;
};

type ContentBlocks_ContentBlocks_CinemaItem_BlockType_Fragment = {
    __typename: 'contentBlocks_cinemaItem_BlockType';
    id?: string | null;
    itemId?: Array<string> | null;
};

type ContentBlocks_ContentBlocks_DataTable_BlockType_Fragment = {
    __typename: 'contentBlocks_dataTable_BlockType';
    id?: string | null;
    table?: {
        __typename?: 'table_TableMakerField';
        rows?: Array<Array<string | null> | null> | null;
        columns?: Array<{
            __typename?: 'table_TableMakerField_column';
            width?: string | null;
            heading?: string | null;
            align?: string | null;
        } | null> | null;
    } | null;
};

type ContentBlocks_ContentBlocks_DocSchoolFilmTile_BlockType_Fragment = {
    __typename: 'contentBlocks_docSchoolFilmTile_BlockType';
    id?: string | null;
    filmId?: Array<string> | null;
};

type ContentBlocks_ContentBlocks_FestivalItem_BlockType_Fragment = {
    __typename: 'contentBlocks_festivalItem_BlockType';
    itemId?: Array<string> | null;
};

type ContentBlocks_ContentBlocks_GoogleMapEmbed_BlockType_Fragment = {
    __typename: 'contentBlocks_googleMapEmbed_BlockType';
    embedUrl?: string | null;
};

type ContentBlocks_ContentBlocks_Heading_BlockType_Fragment = {
    __typename: 'contentBlocks_heading_BlockType';
    id?: string | null;
    text?: string | null;
};

type ContentBlocks_ContentBlocks_Image_BlockType_Fragment = {
    __typename: 'contentBlocks_image_BlockType';
    id?: string | null;
    caption?: string | null;
    width?: boolean | null;
    image: Array<{
        __typename?: 'uploads_Asset';
        id?: string | null;
        alt?: string | null;
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        blurHash?: string | null;
        webp_100w?: string | null;
        webp_400w?: string | null;
        webp_860w?: string | null;
        webp_1280w?: string | null;
        webp_1920w?: string | null;
        webp_2560w?: string | null;
        jpeg_100w?: string | null;
        jpeg_400w?: string | null;
        jpeg_860w?: string | null;
        jpeg_1280w?: string | null;
        jpeg_1920w?: string | null;
        jpeg_2560w?: string | null;
    } | null>;
};

type ContentBlocks_ContentBlocks_Images_BlockType_Fragment = {
    __typename: 'contentBlocks_images_BlockType';
    id?: string | null;
    caption?: string | null;
    images: Array<{
        __typename?: 'uploads_Asset';
        id?: string | null;
        alt?: string | null;
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        blurHash?: string | null;
        webp_100w?: string | null;
        webp_400w?: string | null;
        webp_860w?: string | null;
        webp_1280w?: string | null;
        webp_1920w?: string | null;
        webp_2560w?: string | null;
        jpeg_100w?: string | null;
        jpeg_400w?: string | null;
        jpeg_860w?: string | null;
        jpeg_1280w?: string | null;
        jpeg_1920w?: string | null;
        jpeg_2560w?: string | null;
    } | null>;
};

type ContentBlocks_ContentBlocks_Navigation_BlockType_Fragment = {
    __typename: 'contentBlocks_navigation_BlockType';
    id?: string | null;
    blocks: Array<{
        __typename?: 'blocks_BlockType';
        id?: string | null;
        color?: string | null;
        label?: string | null;
        thumbnail: Array<{
            __typename?: 'uploads_Asset';
            id?: string | null;
            alt?: string | null;
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            blurHash?: string | null;
            webp_100w?: string | null;
            webp_400w?: string | null;
            webp_860w?: string | null;
            webp_1280w?: string | null;
            webp_1920w?: string | null;
            webp_2560w?: string | null;
            jpeg_100w?: string | null;
            jpeg_400w?: string | null;
            jpeg_860w?: string | null;
            jpeg_1280w?: string | null;
            jpeg_1920w?: string | null;
            jpeg_2560w?: string | null;
        } | null>;
        linkObject?: {
            __typename?: 'linkField_Link';
            url?: string | null;
            element?:
                | { __typename?: 'Address'; uri?: string | null }
                | { __typename?: 'Element'; uri?: string | null }
                | { __typename?: 'User'; uri?: string | null }
                | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                | { __typename?: 'blocks_BlockType'; uri?: string | null }
                | { __typename?: 'carousel_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                | { __typename?: 'destination_BlockType'; uri?: string | null }
                | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                | { __typename?: 'links_BlockType'; uri?: string | null }
                | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                | { __typename?: 'newsCategories_Category'; uri?: string | null }
                | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
                | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                | { __typename?: 'slides_BlockType'; uri?: string | null }
                | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                | { __typename?: 'storyCategories_Category'; uri?: string | null }
                | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                | { __typename?: 'uploads_Asset'; uri?: string | null }
                | null;
        } | null;
    } | null>;
};

type ContentBlocks_ContentBlocks_Paragraph_BlockType_Fragment = {
    __typename: 'contentBlocks_paragraph_BlockType';
    id?: string | null;
    text?: string | null;
};

type ContentBlocks_ContentBlocks_ProfessionalsFilm_BlockType_Fragment = {
    __typename: 'contentBlocks_professionalsFilm_BlockType';
    filmId?: Array<string> | null;
};

type ContentBlocks_ContentBlocks_Quote_BlockType_Fragment = {
    __typename: 'contentBlocks_quote_BlockType';
    id?: string | null;
    quote?: string | null;
    source?: string | null;
};

type ContentBlocks_ContentBlocks_Ranking_BlockType_Fragment = {
    __typename: 'contentBlocks_ranking_BlockType';
    id?: string | null;
    rankingItems: Array<{
        __typename?: 'rankingItems_BlockType';
        id?: string | null;
        label?: string | null;
        linkObject?: {
            __typename?: 'linkField_Link';
            text?: string | null;
            url?: string | null;
            ariaLabel?: string | null;
            target?: string | null;
            element?:
                | { __typename?: 'Address'; uri?: string | null }
                | { __typename?: 'Element'; uri?: string | null }
                | { __typename?: 'User'; uri?: string | null }
                | { __typename?: 'accordionItems_BlockType'; uri?: string | null }
                | { __typename?: 'blocks_BlockType'; uri?: string | null }
                | { __typename?: 'carousel_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_accordion_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_archiveFilm_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_button_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_carousel_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_cinemaItem_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_dataTable_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_docSchoolFilmTile_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_festivalItem_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_googleMapEmbed_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_heading_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_image_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_images_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_navigation_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_paragraph_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_professionalsFilm_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_quote_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_ranking_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_sponsorBlock_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_styledTable_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_ticketBlock_BlockType'; uri?: string | null }
                | { __typename?: 'contentBlocks_videoPlayer_BlockType'; uri?: string | null }
                | { __typename?: 'defaultSponsorBannerSet_GlobalSet'; uri?: string | null }
                | { __typename?: 'destination_BlockType'; uri?: string | null }
                | { __typename?: 'featuredStories_featuredStory_BlockType'; uri?: string | null }
                | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
                | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
                | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
                | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
                | { __typename?: 'festivalfilmAlert_GlobalSet'; uri?: string | null }
                | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
                | { __typename?: 'homeCta_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeCtas_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeDestinationList_destination_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_destinationList_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_festivalImpressions_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_linkCarousel_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_listGroups_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_longread_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_newsletterSignup_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_recentNews_BlockType'; uri?: string | null }
                | { __typename?: 'homeFestivalBlocks_video_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroCarousel_slide_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroEvents_event_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalProfessionals_splitScreenHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalProfessionals_wideImageHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalPublic_splitScreenHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroFestivalPublic_wideImageHero_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroKeyDates_keyDate_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroWidget_cta_BlockType'; uri?: string | null }
                | { __typename?: 'homeHeroWidget_upcomingInCinema_BlockType'; uri?: string | null }
                | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
                | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
                | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
                | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
                | { __typename?: 'links_BlockType'; uri?: string | null }
                | { __typename?: 'listGroup_BlockType'; uri?: string | null }
                | { __typename?: 'listItems_listItem_BlockType'; uri?: string | null }
                | { __typename?: 'newsCategories_Category'; uri?: string | null }
                | { __typename?: 'partnerListings_partner_BlockType'; uri?: string | null }
                | { __typename?: 'partnerLogos_partner_BlockType'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
                | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
                | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
                | { __typename?: 'rankingItems_BlockType'; uri?: string | null }
                | { __typename?: 'redirects_GlobalSet'; uri?: string | null }
                | { __typename?: 'redirects_redirect_BlockType'; uri?: string | null }
                | { __typename?: 'relatedLinks_link_BlockType'; uri?: string | null }
                | { __typename?: 'slides_BlockType'; uri?: string | null }
                | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
                | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
                | { __typename?: 'sponsor_BlockType'; uri?: string | null }
                | { __typename?: 'storyCategories_Category'; uri?: string | null }
                | { __typename?: 'styledTableHeadings_BlockType'; uri?: string | null }
                | { __typename?: 'styledTableRows_BlockType'; uri?: string | null }
                | { __typename?: 'uploads_Asset'; uri?: string | null }
                | null;
        } | null;
    } | null>;
};

type ContentBlocks_ContentBlocks_SponsorBlock_BlockType_Fragment = {
    __typename: 'contentBlocks_sponsorBlock_BlockType';
    id?: string | null;
    sponsors: Array<{
        __typename?: 'sponsor_BlockType';
        id?: string | null;
        title?: string | null;
        linkTo?: string | null;
        logo: Array<{
            __typename?: 'uploads_Asset';
            id?: string | null;
            alt?: string | null;
            url?: string | null;
            title?: string | null;
            width?: number | null;
            height?: number | null;
            blurHash?: string | null;
            webp_100w?: string | null;
            webp_400w?: string | null;
            webp_860w?: string | null;
            webp_1280w?: string | null;
            webp_1920w?: string | null;
            webp_2560w?: string | null;
            jpeg_100w?: string | null;
            jpeg_400w?: string | null;
            jpeg_860w?: string | null;
            jpeg_1280w?: string | null;
            jpeg_1920w?: string | null;
            jpeg_2560w?: string | null;
        } | null>;
    } | null>;
};

type ContentBlocks_ContentBlocks_StyledTable_BlockType_Fragment = {
    __typename: 'contentBlocks_styledTable_BlockType';
    id?: string | null;
    styledTableRows: Array<{
        __typename?: 'styledTableRows_BlockType';
        id?: string | null;
        column1?: string | null;
        column2?: string | null;
        column3?: string | null;
    } | null>;
    styledTableHeadings: Array<{
        __typename?: 'styledTableHeadings_BlockType';
        id?: string | null;
        heading1?: string | null;
        heading2?: string | null;
        heading3?: string | null;
    } | null>;
};

type ContentBlocks_ContentBlocks_TicketBlock_BlockType_Fragment = {
    __typename: 'contentBlocks_ticketBlock_BlockType';
    id?: string | null;
    showOrTicketId?: string | null;
    heading?: string | null;
    location?: string | null;
    startDate?: any | null;
    endDate?: any | null;
    ticketType?: string | null;
};

type ContentBlocks_ContentBlocks_VideoPlayer_BlockType_Fragment = {
    __typename: 'contentBlocks_videoPlayer_BlockType';
    id?: string | null;
    videoUrl?: string | null;
    caption?: string | null;
};

export type ContentBlocksFragment =
    | ContentBlocks_ContentBlocks_Accordion_BlockType_Fragment
    | ContentBlocks_ContentBlocks_ArchiveFilm_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Button_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Carousel_BlockType_Fragment
    | ContentBlocks_ContentBlocks_CinemaItem_BlockType_Fragment
    | ContentBlocks_ContentBlocks_DataTable_BlockType_Fragment
    | ContentBlocks_ContentBlocks_DocSchoolFilmTile_BlockType_Fragment
    | ContentBlocks_ContentBlocks_FestivalItem_BlockType_Fragment
    | ContentBlocks_ContentBlocks_GoogleMapEmbed_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Heading_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Image_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Images_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Navigation_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Paragraph_BlockType_Fragment
    | ContentBlocks_ContentBlocks_ProfessionalsFilm_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Quote_BlockType_Fragment
    | ContentBlocks_ContentBlocks_Ranking_BlockType_Fragment
    | ContentBlocks_ContentBlocks_SponsorBlock_BlockType_Fragment
    | ContentBlocks_ContentBlocks_StyledTable_BlockType_Fragment
    | ContentBlocks_ContentBlocks_TicketBlock_BlockType_Fragment
    | ContentBlocks_ContentBlocks_VideoPlayer_BlockType_Fragment;

export type DefaultCmsImageFragment = {
    __typename?: 'uploads_Asset';
    id?: string | null;
    alt?: string | null;
    url?: string | null;
    title?: string | null;
    width?: number | null;
    height?: number | null;
    blurHash?: string | null;
    webp_100w?: string | null;
    webp_400w?: string | null;
    webp_860w?: string | null;
    webp_1280w?: string | null;
    webp_1920w?: string | null;
    webp_2560w?: string | null;
    jpeg_100w?: string | null;
    jpeg_400w?: string | null;
    jpeg_860w?: string | null;
    jpeg_1280w?: string | null;
    jpeg_1920w?: string | null;
    jpeg_2560w?: string | null;
};

export type LinkFragment = {
    __typename?: 'linkField_Link';
    text?: string | null;
    url?: string | null;
    title?: string | null;
    type?: string | null;
};

export type SponsorFragment = {
    __typename: 'sponsorBanners_banner_Entry';
    id?: string | null;
    linkUrl?: string | null;
    title?: string | null;
    bannerImage: Array<{
        __typename?: 'uploads_Asset';
        id?: string | null;
        alt?: string | null;
        url?: string | null;
        title?: string | null;
        width?: number | null;
        height?: number | null;
        blurHash?: string | null;
        webp_100w?: string | null;
        webp_400w?: string | null;
        webp_860w?: string | null;
        webp_1280w?: string | null;
        webp_1920w?: string | null;
        webp_2560w?: string | null;
        jpeg_100w?: string | null;
        jpeg_400w?: string | null;
        jpeg_860w?: string | null;
        jpeg_1280w?: string | null;
        jpeg_1920w?: string | null;
        jpeg_2560w?: string | null;
    } | null>;
};

export type SponsorSetFragment = {
    __typename: 'sponsorBannerSets_bannerSet_Entry';
    sponsorBanners: Array<
        | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry' }
        | { __typename?: 'festivalContentPages_guestbookPage_Entry' }
        | { __typename?: 'festivalContentPages_informationPage_Entry' }
        | { __typename?: 'festivalContentPages_navigationPage_Entry' }
        | { __typename?: 'festivalContentPages_newsOverview_Entry' }
        | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry' }
        | { __typename?: 'festivalContentPages_programPage_Entry' }
        | { __typename?: 'festivalHomepage_festivalHomepage_Entry' }
        | { __typename?: 'festivalNewsArticles_news_Entry' }
        | { __typename?: 'festivalPathways_pathway_Entry' }
        | { __typename?: 'footer_footerLink_Entry' }
        | { __typename?: 'instituteContentPages_archiveSearchPage_Entry' }
        | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry' }
        | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry' }
        | { __typename?: 'instituteContentPages_formPage_Entry' }
        | { __typename?: 'instituteContentPages_informationPage_Entry' }
        | { __typename?: 'instituteContentPages_navigationPage_Entry' }
        | { __typename?: 'instituteContentPages_newsOverview_Entry' }
        | { __typename?: 'instituteHomepage_instituteHomepage_Entry' }
        | { __typename?: 'instituteNewsArticles_news_Entry' }
        | { __typename?: 'instituteStrands_strand_Entry' }
        | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
        | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry' }
        | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
        | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry' }
        | { __typename?: 'professionalsContentPages_informationPage_Entry' }
        | { __typename?: 'professionalsContentPages_navigationPage_Entry' }
        | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
        | { __typename?: 'professionalsContentPages_storiesOverview_Entry' }
        | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry' }
        | { __typename?: 'professionalsStories_story_Entry' }
        | { __typename?: 'sponsorBannerSets_bannerSet_Entry' }
        | {
              __typename: 'sponsorBanners_banner_Entry';
              id?: string | null;
              linkUrl?: string | null;
              title?: string | null;
              bannerImage: Array<{
                  __typename?: 'uploads_Asset';
                  id?: string | null;
                  alt?: string | null;
                  url?: string | null;
                  title?: string | null;
                  width?: number | null;
                  height?: number | null;
                  blurHash?: string | null;
                  webp_100w?: string | null;
                  webp_400w?: string | null;
                  webp_860w?: string | null;
                  webp_1280w?: string | null;
                  webp_1920w?: string | null;
                  webp_2560w?: string | null;
                  jpeg_100w?: string | null;
                  jpeg_400w?: string | null;
                  jpeg_860w?: string | null;
                  jpeg_1280w?: string | null;
                  jpeg_1920w?: string | null;
                  jpeg_2560w?: string | null;
              } | null>;
          }
        | null
    >;
};

export type NewsPathQueryVariables = Exact<{
    site?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type NewsPathQuery = {
    __typename?: 'Query';
    entry?:
        | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_guestbookPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_newsOverview_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalContentPages_programPage_Entry'; uri?: string | null }
        | { __typename?: 'festivalHomepage_festivalHomepage_Entry'; uri?: string | null }
        | { __typename?: 'festivalNewsArticles_news_Entry'; uri?: string | null }
        | { __typename?: 'festivalPathways_pathway_Entry'; uri?: string | null }
        | { __typename?: 'footer_footerLink_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_archiveSearchPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_formPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'instituteContentPages_newsOverview_Entry'; uri?: string | null }
        | { __typename?: 'instituteHomepage_instituteHomepage_Entry'; uri?: string | null }
        | { __typename?: 'instituteNewsArticles_news_Entry'; uri?: string | null }
        | { __typename?: 'instituteStrands_strand_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_informationPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_navigationPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsContentPages_storiesOverview_Entry'; uri?: string | null }
        | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry'; uri?: string | null }
        | { __typename?: 'professionalsStories_story_Entry'; uri?: string | null }
        | { __typename?: 'sponsorBannerSets_bannerSet_Entry'; uri?: string | null }
        | { __typename?: 'sponsorBanners_banner_Entry'; uri?: string | null }
        | null;
};

export type SponsorsQueryVariables = Exact<{ [key: string]: never }>;

export type SponsorsQuery = {
    __typename?: 'Query';
    globalSet?:
        | {
              __typename: 'defaultSponsorBannerSet_GlobalSet';
              sponsorBannerSet: Array<
                  | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry' }
                  | { __typename?: 'festivalContentPages_guestbookPage_Entry' }
                  | { __typename?: 'festivalContentPages_informationPage_Entry' }
                  | { __typename?: 'festivalContentPages_navigationPage_Entry' }
                  | { __typename?: 'festivalContentPages_newsOverview_Entry' }
                  | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry' }
                  | { __typename?: 'festivalContentPages_programPage_Entry' }
                  | { __typename?: 'festivalHomepage_festivalHomepage_Entry' }
                  | { __typename?: 'festivalNewsArticles_news_Entry' }
                  | { __typename?: 'festivalPathways_pathway_Entry' }
                  | { __typename?: 'footer_footerLink_Entry' }
                  | { __typename?: 'instituteContentPages_archiveSearchPage_Entry' }
                  | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry' }
                  | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry' }
                  | { __typename?: 'instituteContentPages_formPage_Entry' }
                  | { __typename?: 'instituteContentPages_informationPage_Entry' }
                  | { __typename?: 'instituteContentPages_navigationPage_Entry' }
                  | { __typename?: 'instituteContentPages_newsOverview_Entry' }
                  | { __typename?: 'instituteHomepage_instituteHomepage_Entry' }
                  | { __typename?: 'instituteNewsArticles_news_Entry' }
                  | { __typename?: 'instituteStrands_strand_Entry' }
                  | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_informationPage_Entry' }
                  | { __typename?: 'professionalsContentPages_navigationPage_Entry' }
                  | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
                  | { __typename?: 'professionalsContentPages_storiesOverview_Entry' }
                  | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry' }
                  | { __typename?: 'professionalsStories_story_Entry' }
                  | {
                        __typename: 'sponsorBannerSets_bannerSet_Entry';
                        sponsorBanners: Array<
                            | { __typename?: 'festivalContentPages_festivalCollectionPage_Entry' }
                            | { __typename?: 'festivalContentPages_guestbookPage_Entry' }
                            | { __typename?: 'festivalContentPages_informationPage_Entry' }
                            | { __typename?: 'festivalContentPages_navigationPage_Entry' }
                            | { __typename?: 'festivalContentPages_newsOverview_Entry' }
                            | { __typename?: 'festivalContentPages_pressAndIndustryPage_Entry' }
                            | { __typename?: 'festivalContentPages_programPage_Entry' }
                            | { __typename?: 'festivalHomepage_festivalHomepage_Entry' }
                            | { __typename?: 'festivalNewsArticles_news_Entry' }
                            | { __typename?: 'festivalPathways_pathway_Entry' }
                            | { __typename?: 'footer_footerLink_Entry' }
                            | { __typename?: 'instituteContentPages_archiveSearchPage_Entry' }
                            | { __typename?: 'instituteContentPages_cinemaProgramPage_Entry' }
                            | { __typename?: 'instituteContentPages_docschoolCollectionPage_Entry' }
                            | { __typename?: 'instituteContentPages_formPage_Entry' }
                            | { __typename?: 'instituteContentPages_informationPage_Entry' }
                            | { __typename?: 'instituteContentPages_navigationPage_Entry' }
                            | { __typename?: 'instituteContentPages_newsOverview_Entry' }
                            | { __typename?: 'instituteHomepage_instituteHomepage_Entry' }
                            | { __typename?: 'instituteNewsArticles_news_Entry' }
                            | { __typename?: 'instituteStrands_strand_Entry' }
                            | { __typename?: 'professionalsContentPages_berthaFundCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_doclabCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_docsForSaleCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_forumCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_informationPage_Entry' }
                            | { __typename?: 'professionalsContentPages_navigationPage_Entry' }
                            | { __typename?: 'professionalsContentPages_projectSpaceCollectionPage_Entry' }
                            | { __typename?: 'professionalsContentPages_storiesOverview_Entry' }
                            | { __typename?: 'professionalsHomepage_professionalsHomepage_Entry' }
                            | { __typename?: 'professionalsStories_story_Entry' }
                            | { __typename?: 'sponsorBannerSets_bannerSet_Entry' }
                            | {
                                  __typename: 'sponsorBanners_banner_Entry';
                                  id?: string | null;
                                  linkUrl?: string | null;
                                  title?: string | null;
                                  bannerImage: Array<{
                                      __typename?: 'uploads_Asset';
                                      id?: string | null;
                                      alt?: string | null;
                                      url?: string | null;
                                      title?: string | null;
                                      width?: number | null;
                                      height?: number | null;
                                      blurHash?: string | null;
                                      webp_100w?: string | null;
                                      webp_400w?: string | null;
                                      webp_860w?: string | null;
                                      webp_1280w?: string | null;
                                      webp_1920w?: string | null;
                                      webp_2560w?: string | null;
                                      jpeg_100w?: string | null;
                                      jpeg_400w?: string | null;
                                      jpeg_860w?: string | null;
                                      jpeg_1280w?: string | null;
                                      jpeg_1920w?: string | null;
                                      jpeg_2560w?: string | null;
                                  } | null>;
                              }
                            | null
                        >;
                    }
                  | { __typename?: 'sponsorBanners_banner_Entry' }
                  | null
              >;
          }
        | { __typename?: 'festivalfilmAlert_GlobalSet' }
        | { __typename?: 'redirects_GlobalSet' }
        | null;
};

export const DefaultCmsImageFragmentDoc = `
    fragment DefaultCmsImage on AssetInterface {
  id
  blurHash: url @assetToBlurHash(asUri: false)
  webp_100w: url @transform(width: 100, format: "webp")
  webp_400w: url @transform(width: 400, format: "webp")
  webp_860w: url @transform(width: 860, format: "webp")
  webp_1280w: url @transform(width: 1280, format: "webp")
  webp_1920w: url @transform(width: 1920, format: "webp")
  webp_2560w: url @transform(width: 2560, format: "webp")
  jpeg_100w: url @transform(width: 100, format: "jpeg")
  jpeg_400w: url @transform(width: 400, format: "jpeg")
  jpeg_860w: url @transform(width: 860, format: "jpeg")
  jpeg_1280w: url @transform(width: 1280, format: "jpeg")
  jpeg_1920w: url @transform(width: 1920, format: "jpeg")
  jpeg_2560w: url @transform(width: 2560, format: "jpeg")
  alt
  url
  title
  width
  height
}
    `;
export const ContentBlocksFragmentDoc = `
    fragment ContentBlocks on contentBlocks_MatrixField {
  __typename
  ... on contentBlocks_heading_BlockType {
    id
    text
  }
  ... on contentBlocks_paragraph_BlockType {
    id
    text
  }
  ... on contentBlocks_image_BlockType {
    id
    caption
    image {
      ...DefaultCmsImage
    }
    width
  }
  ... on contentBlocks_googleMapEmbed_BlockType {
    embedUrl
  }
  ... on contentBlocks_images_BlockType {
    id
    caption
    images {
      ...DefaultCmsImage
    }
  }
  ... on contentBlocks_button_BlockType {
    id
    label
    linkObject {
      url
      element {
        uri
      }
      target
    }
  }
  ... on contentBlocks_carousel_BlockType {
    id
    slides {
      ... on slides_BlockType {
        id
        caption
        image {
          ...DefaultCmsImage
        }
      }
    }
  }
  ... on contentBlocks_videoPlayer_BlockType {
    id
    videoUrl
    caption
  }
  ... on contentBlocks_navigation_BlockType {
    id
    blocks {
      ... on blocks_BlockType {
        id
        color
        thumbnail {
          ...DefaultCmsImage
        }
        label
        linkObject {
          url
          element {
            uri
          }
        }
      }
    }
  }
  ... on contentBlocks_quote_BlockType {
    id
    quote
    source
  }
  ... on contentBlocks_archiveFilm_BlockType {
    id
    filmId
  }
  ... on contentBlocks_cinemaItem_BlockType {
    id
    itemId
  }
  ... on contentBlocks_docSchoolFilmTile_BlockType {
    id
    filmId
  }
  ... on contentBlocks_professionalsFilm_BlockType {
    filmId
  }
  ... on contentBlocks_festivalItem_BlockType {
    itemId
  }
  ... on contentBlocks_dataTable_BlockType {
    id
    table {
      columns {
        width
        heading
        align
      }
      rows
    }
  }
  ... on contentBlocks_styledTable_BlockType {
    id
    styledTableRows {
      ... on styledTableRows_BlockType {
        id
        column1
        column2
        column3
      }
    }
    styledTableHeadings {
      ... on styledTableHeadings_BlockType {
        id
        heading1
        heading2
        heading3
      }
    }
  }
  ... on contentBlocks_ranking_BlockType {
    id
    rankingItems {
      ... on rankingItems_BlockType {
        id
        label
        linkObject {
          text
          url
          ariaLabel
          element {
            uri
          }
          target
        }
      }
    }
  }
  ... on contentBlocks_accordion_BlockType {
    id
    accordionItems {
      ... on accordionItems_BlockType {
        id
        heading
        body
      }
    }
  }
  ... on contentBlocks_ticketBlock_BlockType {
    id
    showOrTicketId
    heading
    location
    startDate
    endDate
    ticketType
  }
  ... on contentBlocks_sponsorBlock_BlockType {
    id
    sponsors: sponsor {
      ... on sponsor_BlockType {
        id
        title
        linkTo
        logo {
          ...DefaultCmsImage
        }
      }
    }
  }
}
    `;
export const LinkFragmentDoc = `
    fragment Link on linkField_Link {
  text
  url
  title
  type
}
    `;
export const SponsorFragmentDoc = `
    fragment Sponsor on sponsorBanners_banner_Entry {
  __typename
  id
  bannerImage {
    ...DefaultCmsImage
  }
  linkUrl
  title
}
    `;
export const SponsorSetFragmentDoc = `
    fragment SponsorSet on sponsorBannerSets_bannerSet_Entry {
  __typename
  sponsorBanners {
    ...Sponsor
  }
}
    `;
export const ContentBlocksDocument = `
    query contentBlocks($slug: [String]!, $site: [String]) {
  entry(slug: $slug, site: $site) {
    __typename
    id
    ... on instituteContentPages_informationPage_Entry {
      contentBlocks {
        ...ContentBlocks
      }
    }
    ... on professionalsContentPages_informationPage_Entry {
      contentBlocks {
        ...ContentBlocks
      }
    }
  }
}
    ${ContentBlocksFragmentDoc}
${DefaultCmsImageFragmentDoc}`;
export const useContentBlocksQuery = <TData = ContentBlocksQuery, TError = unknown>(
    variables: ContentBlocksQueryVariables,
    options?: UseQueryOptions<ContentBlocksQuery, TError, TData>
) =>
    useQuery<ContentBlocksQuery, TError, TData>(
        ['contentBlocks', variables],
        useCraft<ContentBlocksQuery, ContentBlocksQueryVariables>(ContentBlocksDocument).bind(null, variables),
        options
    );
useContentBlocksQuery.document = ContentBlocksDocument;

useContentBlocksQuery.getKey = (variables: ContentBlocksQueryVariables) => ['contentBlocks', variables];
export const NewsPathDocument = `
    query newsPath($site: [String!]) {
  entry(site: $site, type: "newsOverview") {
    uri
  }
}
    `;
export const useNewsPathQuery = <TData = NewsPathQuery, TError = unknown>(
    variables?: NewsPathQueryVariables,
    options?: UseQueryOptions<NewsPathQuery, TError, TData>
) =>
    useQuery<NewsPathQuery, TError, TData>(
        variables === undefined ? ['newsPath'] : ['newsPath', variables],
        useCraft<NewsPathQuery, NewsPathQueryVariables>(NewsPathDocument).bind(null, variables),
        options
    );
useNewsPathQuery.document = NewsPathDocument;

useNewsPathQuery.getKey = (variables?: NewsPathQueryVariables) =>
    variables === undefined ? ['newsPath'] : ['newsPath', variables];
export const SponsorsDocument = `
    query sponsors {
  globalSet {
    ... on defaultSponsorBannerSet_GlobalSet {
      __typename
      sponsorBannerSet {
        ...SponsorSet
      }
    }
  }
}
    ${SponsorSetFragmentDoc}
${SponsorFragmentDoc}
${DefaultCmsImageFragmentDoc}`;
export const useSponsorsQuery = <TData = SponsorsQuery, TError = unknown>(
    variables?: SponsorsQueryVariables,
    options?: UseQueryOptions<SponsorsQuery, TError, TData>
) =>
    useQuery<SponsorsQuery, TError, TData>(
        variables === undefined ? ['sponsors'] : ['sponsors', variables],
        useCraft<SponsorsQuery, SponsorsQueryVariables>(SponsorsDocument).bind(null, variables),
        options
    );
useSponsorsQuery.document = SponsorsDocument;

useSponsorsQuery.getKey = (variables?: SponsorsQueryVariables) =>
    variables === undefined ? ['sponsors'] : ['sponsors', variables];
